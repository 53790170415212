<template>
    <div class="text_element" v-bind:class="{ active: textElementActive || textElementEditable }" v-on:click="textElementToggle">
        <div class="text_element_input" :contenteditable="textElementEditable" v-on:dblclick="textElementToggleEditable">
            {{ textElementText }}
        </div>

        <div class="element_size_edit top_right radial_circle"></div>
        <div class="element_size_edit right"></div>
        <div class="element_size_edit bottom radial_circle"></div>
        <div class="text_element_not_editable" v-if="textElementEditable" v-on:click="textElementToggle(), textElementToggleEditable()"></div>
    </div>
</template>

<script>
export default {
    name: "TextElement",
	props: {
        textElementText: String,
    },
    data: function () {
		return {
            textElementActive: false,
            textElementEditable: false
		}
    },
    methods: {
		textElementToggle() {
            this.textElementActive = !this.textElementActive;
        },
		textElementToggleEditable() {
            return (this.textElementEditable = !this.textElementEditable);
        },
	}
};
</script>