<template>
    <!-- v-dragscroll:nochilddrag -->
    <div class="drag-wrapper">
        <div
                class="popup img_prop_popup ch-popups"
                v-if="popupLayerPropVisible || popupGraphicPropVisible"
                ref="popupWindow"
                :style="{ top: '58px', left: '323px', 'z-index': 2 }"
                v-custom-drag
        >
            <div class="popup_header">
                <h3 v-if="popupLayerPropVisible">image properties</h3>
                <h3 v-if="popupGraphicPropVisible">graphic properties</h3>
                <a class="close_popup_btn" v-on:click="popUpImgPropChange">
                    <span class="inch-icon inch-close-icon"></span>
                </a>
            </div>
            <div class="popup_body">
                <div class="popup_body_row">
                    <div class="popup_body_row_item">

                        <ColorInput
                                :key="dispatchUpdateTrigger"
                                :defColor="layerPropConfig.color"
                                @inputRGBA="setOverlayColor"
                                :checkColor="!!layerPropConfig.color"
                                textLabel="Color Overlay"
                        />
                    </div>
                    <div class="popup_body_row_item">
                        <Range
                                rangeSliderId="editOpacity"
                                :key="dispatchUpdateTrigger"
                                :rangeSliderValue="layerPropConfig.intensity"
                                rangeSliderLabel="Intensity"
                                rangeSliderMeasure="%"
                                rangeSliderClass="range-blue"
                                :minValue="0"
                                :maxValue="100"
                                :disabled="isDisableIntensitySlider"
                                :defaultValue="30"
                                :changeValue="setOverlayIntensity"
                                @dragEnd="setOverlayIntensity($event, true)"
                        />
                    </div>
                </div>
                <div class="popup_body_row">
                    <div class="popup_body_row_item">
                        <div class="actions-group double-btn popup_double_btns_wrpr">
                            <a
                                    href="#"
                                    class="button btn-action duplicate"
                                    v-on:click="popupLayerPropShow"
                            >Layer Properties</a
                            >
                            <a
                                    class="btn-action trash"
                                    v-on:click="deleteSelectedGraphic"
                                    v-tooltip.top="'Delete'"
                            >
                                <span class="inch-trash-icon"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PopupLayerProp
                class="ch-popups"
                :style="{ top: '310px', left: '323px' }"
                v-if="
        isPopupLayerPropShow &&
        (popupLayerPropVisible || popupGraphicPropVisible)
      "
                :popupLayerPropShow="popupLayerPropShow"
                ref="popupLayer"
        />

        <div class="editing_img_wrpr with_element" :data-width="getRawZoom">
            <TextElement
                    v-if="textElementState"
                    textElementText="Double-click to edit text"
            />

            <ImageElement :imgPath="ImageElementSrc" classs="active">
                <div v-if="getGuides" class="img-overlay" :style="getBounds()"></div>
            </ImageElement>

            <div v-if="!isMobileView">
                <AdSavePanel v-if="isSaveAd" />
                <AdPanel v-if="isRightAd" :content="isRightAdContent" adPosition="right" />
                <AdPanel v-if="isBottomAd" :content="isBottomAdContent" adPosition="bottom" />
            </div>


        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ImageElement from "@/components/ImageElement.vue";
    import TextElement from "@/components/TextElement.vue";
    import ColorInput from "@/components/ColorInput.vue";
    import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";
    import Range from "@/components/Range.vue";
    import AdPanel from "@/components/AdPanel.vue";
    import AdSavePanel from "@/components/AdSavePanel.vue";

    export default {
        name: "manager",
        components: {
            ImageElement,
            TextElement,
            ColorInput,
            PopupLayerProp,
            Range,
            AdSavePanel,
            AdPanel
        },
        data: function () {
            return {
                current: {
                    x: 0,
                    y: 0,
                    zoom: 1
                },
                isMobileView: false,
                isDisableIntensitySlider: true,
                rangeSliderValue: 50,
                ImageElementSrc: require("@/assets/img/man_image.png"),
                startedPhotos: [
                    require("@/assets/img/started_photo_1.png"),
                    require("@/assets/img/started_photo_2.png"),
                    require("@/assets/img/started_photo_3.png"),
                ],
                zoom: 100,
                isPopupLayerPropShow: false,
                dispatchUpdateTrigger: false,
                layerPropConfig: {},
            };
        },
        watch: {
            popupLayerPropVisible(isVisible) {
                if (!isVisible) {
                    this.isPopupLayerPropShow = false;
                }
            },

            getGlobalLayerParams(layerPropConfig) {
                this.setLayerConfig(layerPropConfig);
            },

            currentZoomPosition(val) {
                this.current = val;
            }
        },

        computed: {
            currentZoomPosition: {
                get() {
                    return this.$store.state.currentZoomPosition;
                },
                set(val) {
                    this.$store.state.currentZoomPosition = val;
                },
            },

            getZoom() {
                return this.setZoom(this.$store.state.global.zoom);
            },

            getRawZoom() {
                return this.$store.state.global.zoom;
            },

            textElementState() {
                return this.$store.getters.TEXTELEMENTSTATES;
            },
            getGuides() {
                return this.$store.state.settings.showGuides;
            },
            popupLayerPropVisible() {
                return this.$store.state.global.popupLayerPropVisible;
            },

            popupGraphicPropVisible() {
                return this.$store.state.global.showPropertiesGraphics;
            },

            getGlobalLayerParams() {
                return {
                    ...this.$store.state.globalLayerPopUp.default,
                    ...this.$store.state.globalLayerPopUp.params,
                };
            },
            isPremiumAccount() {
                return this.$store.state.isPremiumAccount;
            },
            layersBar() {
                return this.$store.state.global.layersPanel;
            },
            isRightAd() {
                return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.right_ad?.is_right_ad) ?? false;
            },
            isRightAdContent() {
                if(!this.isRightAd) return ''
                if(window.innerWidth>=1600) return this.$store.state.ADINFO?.right_ad?.right_script_4k ?? this.$store.state.ADINFO?.right_ad?.right_script ?? '';
                return this.$store.state.ADINFO?.right_ad?.right_script ?? '' ;
            },
            isBottomAd() {
                return (!this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.bottom_ad?.is_bottom_ad) ?? false;
            },
            isBottomAdContent() {
                if(!this.isBottomAd) return ''
                return this.$store.state.ADINFO?.bottom_ad?.bottom_script ?? '' ;
            },
            isSaveAd() {
                return (this.$store.state.popups.afterSaveAd && !this.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.save_ad?.is_save_ad) ?? false;
            },

        },

        mounted() {
            this.isMobileView = this.$store.state.isMobileView;
            //$('.fabricSlide .centered-content')[0].onwheel = e => {
            const pzoomImage = (e, isContainer = false) => {
                if (this.$store.state.lockZoomScroll) return;

                if (e.ctrlKey) return;
                let imageEl = $(".centered-content");

                e.preventDefault();
                e.stopPropagation();
                const dragElement = document.getElementsByClassName("editing_img_wrpr")[0];
                let bounds;
                if ($('.fabricSlide').css('display') != 'none') {
                    bounds = document.querySelector('.fabricSlide').getBoundingClientRect();
                } else {
                    bounds = document.querySelector('.drawFa').getBoundingClientRect();
                }

                const {width, height} = bounds;

                let zoom = _LIB.app.zoomProcentage;// + icr / 100;
                if (zoom != 1) {
                    imageEl.css({'transform': `scale(${zoom})`});

                    _LIB.app.scaleStage(1);
                    _LIB.app.setZoomProcentage(1, true);
                }

                const css = this.$lib.lib.getStylesObject($(".centered-content"));
                let cssZoom = parseFloat((css.transform || '').replace(/[a-zA-Z]|\(|\)/g, ''));

                cssZoom = (cssZoom || 1);
                let count = parseInt(cssZoom) * 10;
                if (count < 10) count = 10;

                const icr = e.deltaY < 0 ? count : (count * -1);
                zoom = cssZoom + icr / 100;

                if (zoom < 0.1) return;
                if (zoom > 4) zoom = 4;

                const marginLeft = parseInt($('.main_n_footer_wrpr').css('margin-left'));
                const marginTop = document.querySelector('.header').getBoundingClientRect().height;

                let oz = cssZoom; //_LIB.app.zoomProcentage;
                let nz = zoom;

                /// offset of container
                let ox = (width / 2) + marginLeft;
                let oy = (height / 2) + marginTop;

                let mx = e.clientX - ox;
                let my = e.clientY - oy;

                let ix = (mx - (this.current.x || 0)) / oz;
                let iy = (my - (this.current.y || 0)) / oz;

                let nx = ix * nz;
                let ny = iy * nz;

                let cx = (ix + (mx - ix) - nx);
                let cy = (iy + (my - iy) - ny);

                this.current.zoom = nz;
                this.current.x = cx;
                this.current.y = cy;
                this.currentZoomPosition = this.current;

                const left = this.current.x;
                const top = this.current.y;
                // if(zoom > 1) {
                imageEl.css({'transform': `scale(${zoom})`});
                this.$lib.app.setFabricZoom(zoom);
                // } else {
                // _LIB.app.scaleStage(zoom);
                // _LIB.app.setZoomProcentage(zoom, true);
                // }
                //console.log('ZOOM ', zoom);
                //if(zoom >= 2.4) debounce(() => _LIB.app.reRenderCanvas(), 30)();
                this.$store.state.global.zoomDashboard = zoom;
                this.$store.state.global.zoomDashboardCounter += 1;

                const isCenter = !(zoom > 1) && !(icr > 0);
                //if(!isCenter) {
                imageEl.css({top: top, left: left});
                //} else {
                // $('.centered-content').css({ left: 0, top: 0 });
                //}
            };

            $('.fabricSlide .centered-content')[0].onwheel = e => pzoomImage(e, true);
            $('.drawFa .centered-content')[0].onwheel = e => pzoomImage(e, true);

            $('.image_element')[0].onwheel = e => {
                e.preventDefault();
                e.stopPropagation();
            };
        },

        beforeMount() {
            if (
                !this.$store.state.userFile &&
                typeof this.$store.state.openAbove == "undefined"
            ) {
                this.$router.push({
                    path: this.$store.state.routerPrefixURL + "/",
                    hash: this.$route.hash
                });
                // this.$router.push(this.$store.state.routerPrefixURL + "/");
                return false;
            }

            this.$store.dispatch("footerToggle", "enabled");
            this.zoom = this.$store.state.global.zoom;
        },
        methods: {
            setLayerConfig(layerPropConfig) {
                for (const key in layerPropConfig) {
                    if (this.layerPropConfig[key] != layerPropConfig[key]) {
                        this.layerPropConfig = layerPropConfig;

                        this.dispatchUpdateTrigger = !this.dispatchUpdateTrigger;
                        break;
                    }
                }

                this.isDisableIntensitySlider = !layerPropConfig.color
                    ? true : this.$lib.lib.rgbaToHexAlpha(layerPropConfig.color).alpha <= 0;
            },

            deleteSelectedGraphic() {
                this.$lib.fabric.deleteSelectedObject();
                this.$store.state.global.popupLayerPropVisible = false;
                this.isPopupLayerPropShow = false;
                this.$store.dispatch("isUpdateCache");
            },

            popupLayerPropShow(isShow) {
                this.isPopupLayerPropShow =
                    typeof isShow == "boolean" ? isShow : !this.isPopupLayerPropShow;
                return this.isPopupLayerPropShow;

                /* this.$store.state.global.popupLayerPropVisible = !this.$store.state.global.popupLayerPropVisible;
                return this.$store.state.global.popupLayerPropVisible; */
            },

            popUpImgPropChange() {
                //this.popUpImgProp = !this.popUpImgProp;

                if (this.$store.state.global.popupLayerPropVisible) {
                    this.$store.state.global.popupLayerPropVisible = false;
                    this.isPopupLayerPropShow = false;
                }

                if (this.$store.state.global.showPropertiesGraphics) {
                    this.$store.state.global.showPropertiesGraphics = false;
                    this.isPopupLayerPropShow = false;
                }
            },

            setOverlayColor(color, toCache) {
                const hex = this.$lib.lib.rgbaToHexAlpha(color);
                this.isDisableIntensitySlider = hex.alpha <= 0;

                const parsedRGBA = this.$lib.lib.rgbaToHexAlpha(color);
                this.layerPropConfig.color = !parsedRGBA.alpha ? undefined : color;
                this.setBlendMode(toCache);
            },

            setBlendMode(toCache) {
                this.$lib.fabric.setBlendMode(
                    !this.layerPropConfig.color ? "clear_filters" : "tint",
                    this.layerPropConfig.intensity,
                    this.layerPropConfig.color,
                    true, undefined, toCache
                );

                this.dispatchConfigLayerProperties();
            },

            dispatchConfigLayerProperties() {
                this.$store.dispatch("globalLayerPopUpParams", {
                    ...this.$store.state.globalLayerPopUp.params,
                    ...this.layerPropConfig,
                });

                this.$store.dispatch("isUpdateCache");
            },

            setOverlayIntensity(intensity, toCache) {
                this.layerPropConfig.intensity = intensity;

                this.setBlendMode(toCache);
            },

            getBounds() {
                var canvas = document.querySelector("#fabricDashboard");
                var bounds = canvas.getBoundingClientRect();
                var style = {
                    width: bounds.width + "px",
                    height: bounds.height + "px",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    backgroundSize: "20px 20px",
                };
                return style;
            },
            setZoom(value) {
                this.zoom = value;
                if (value == "full-screen") {
                    return "width: 100vw";
                } else if (value == "fit-to-screen") {
                    return "width: 100%";
                } else {
                    return "width:" + (1000 * value) / 100 + "px";
                }
            },
        },
    };
</script>

<style>
    .shiftx-enter-active, .shiftx-leave-active {
        transition: all 0.5s ease-in-out;
    }
    .shiftx-enter, .shiftx-leave-to /* .fade-leave-active below version 2.1.8 */ {
        transform :  translateX(250px);
    }
</style>
