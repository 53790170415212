<template>
	<div
		@mousedown="zoomOnMouseDown"
		@mouseup="zoomOnMouseUp"
		@mousemove="zoomOnMouseMove"
		@mouseout="zoomOnMouseUp"
		@contextmenu="openContextMenu"
    :class="[{'image_element_mobile': isMobileView}, 'image_element']"
	>
		<!-- <div class="popup img_prop_popup" v-if="true" ref="popupWindow" v-custom-drag>
			<PopupLayer/>
		</div>-->

		<!-- <PopupLayerProp v-if="true" :popupLayerPropShow="true" ref="popupLayer" /> -->
		<!-- <img :src="imgPath" alt=""> -->
		<!-- <div class="fabric-canvas">
			<canvas id="fabricDashboard"></canvas>
		</div>
				<div class="canvas">
						<canvas id="canvasDashboard" :data-name="userFile.name"></canvas>
		</div>-->
		<div class="absolute-position">
			<div class="centered-content">
				<transition name="slide-fade-popup">
					<PremiumInfo v-if="getPremiumInfoStatus && !isMobileView" />
				</transition>
				<canvas id="canvasDashboard"></canvas>
			</div>
      <transition name="slide-fade-popup">
        <PremiumInfoMobile v-if="getPremiumInfoStatus && isMobileView" :isMobileView="isMobileView"/>
      </transition>
		</div>

		<div class="absolute-position drawFa" style="display: none !important">
			<div class="centered-content">
				<transition name="slide-fade-popup">
					<PremiumInfo v-if="getPremiumInfoStatus && !isMobileView" />
				</transition>
				<canvas id="drawingCanvas"></canvas>
			</div>
		</div>

		<div
			class="absolute-position fabricSlide"
			@drop="dropImage"
			@click="unselectLayers"
      :style="[isMobileView ? {'display': 'none !important'} : '']"
		>
			<div class="centered-content">
				<transition name="slide-fade-popup">
					<PremiumInfo v-if="getPremiumInfoStatus && !isMobileView" />
				</transition>

				<canvas id="fabricDashboard"></canvas>
			</div>
		</div>

		<!-- <div class="element_size_edit top"></div>
				<div class="element_size_edit top_right radial_circle"></div>
				<div class="element_size_edit right"></div>
				<div class="element_size_edit bottom"></div>
				<div class="element_size_edit radial_circle bottom_radial_circle_double"></div>
		<div class="element_size_edit left"></div>-->
		<slot></slot>

		<div class="contextMenu" ref="contextMenu">
			<button class="contextButton" @click="setContextEvent('openFromComputer')">Open from computer</button>
			<button class="contextButton" @click="setContextEvent('saveToComputer')">Save to computer</button>
			<button class="contextButton" @click="setContextEvent('reset')">Reset</button>
		</div>

		<transition name="fade">
			<transition name="slide-fade">
				<div class="changes_applied_wrpr" v-if="popupState.changesApplied" :class="{'changes_applied_mobile' : isMobileView}">
					<div class="plus_watermark notifiers сhanges_applied box_style">
						<div class="title">Changes applied</div>
					</div>
				</div>
			</transition>
		</transition>
	</div>
</template>

<script>
//import PopupLayer from "@/components/popupPanel/PopupLayer.vue";
//import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";
import PremiumInfo from "@/components/PremiumInfo.vue";
import PremiumInfoMobile from "@/components/PremiumInfoMobile.vue";
import Range from "@/components/Range.vue";
import ColorInput from "@/components/ColorInput.vue";
import PopupLayerProp from "@/components/popupPanel/PopupLayerProp.vue";

export default {
	name: "ImageElement",
	components: {
		// PopupLayer,
		// PopupLayerProp
		PremiumInfo,
    PremiumInfoMobile,
		Range, ColorInput,
		PopupLayerProp,
	},
	props: {
		classs: String,
		imgPath: String,
	},
	data: function () {
		return {
			isMobileView: false,
			fsReaderFile: false,
			zoomPositions: {},
			zoomValue: 100,
		};
	},
	computed: {
		current: {
			get() { return this.$store.state.currentZoomPosition; },
			set(val) { return this.$store.state.currentZoomPosition = val; },
		},

		keyDownList() {
			return this.$store.state.keyDownList;
		},

		popupState() {
			return this.$store.getters.POPUPS;
		},

		userFile: function () {
			return this.$store.state.userFile;
		},
		getPremiumInfoStatus() {
			return this.$store.state.premiumStatus;
		},
		hash() {
			const hash = this.$route.hash.split('#')[1]
			if(hash) return `${hash[0].toUpperCase()}${hash.substring(1)}`.replace('-',' ');
			return null;
		},
	},
	updated: function () {
		//ww.$store.state.userFile;
	},
	mounted: function () {
			this.isMobileView =  this.$store.state.isMobileView;
		if (this.userFile) {
			this.renderCanvas(this.userFile);
		} else {
			//alert("1");
			//this.renderCanvas(this.imgPath);
		}

		$(document).on("click", (e) => {
			if(!$(e.target).closest('.contextMenu')[0] && $(e.target).attr('class') !== 'contextMenu') {
			 $('.contextMenu').attr('style', '');
			}
		});
	},
	methods: {
		setContextEvent(eventName) {
			this.$store.state.contextEvent = eventName;
			$('.contextMenu').attr('style', '');
		},

		openContextMenu(e) {
			const bounding = $('.image_element')[0].getBoundingClientRect();
			let left = e.x - bounding.left;
			let top = e.y - bounding.top;

			if(bounding.width < left + 200) {
				left -= 200;
			}

			const elHeight = $('.contextMenu').height();
			if(bounding.height < top + elHeight) {
				top -= elHeight;
			}

			this.$refs.contextMenu.setAttribute("style", `left: ${left}px;top: ${top}px;display: block;`);
			e.preventDefault();
		},

		async zoomOnMouseDown(e) {
			const isSpace = !!this.keyDownList.find((key) => key == 32);
			if (/* this.$lib.fabric.isZoomMode && */ isSpace) {
				const marginLeft = parseInt($('.main_n_footer_wrpr').css('margin-left'));
    			const marginTop = document.querySelector('.header').getBoundingClientRect().height;

				this.dragElement = document.getElementsByClassName("centered-content")[0];

				this.zoomPositions = {
					isDrag: true,
					x: e.pageX,//  - marginLeft,
					y: e.pageY, // - marginTop,,

					...this.calculateZoomPosition(e),
					pageX: e.pageX,
					pageY: e.pageY
				};

				// $('#app').append(`<span style="
				// 	pointer-events: none;
				// 	border-radius: 50%;
				// 	width: 10px;
				// 	height: 10px;
				// 	background-color: rgba(0, 255, 0, .5);
				// 	left: ${this.zoomPositions.pageX}px;
				// 	top: ${this.zoomPositions.pageY}px;
				// 	position: absolute;">
				// </span>`);
			}
		},

		unselectLayers(e) {
			if ($(e.target).hasClass("absolute-position")) {
				this.$lib.app.fabricCanvas.discardActiveObject();
				this.$lib.app.fabricCanvas.renderAll();
			}
		},

		async zoomOnMouseMove(e) {
			const isSpace = !!this.keyDownList.find((key) => key == 32);
			if (this.zoomPositions.isDrag && /* this.$lib.fabric.isZoomMode &&  */isSpace) {
				const imageEl = $(".centered-content");
				const diffX = e.pageX - this.zoomPositions.pageX;
				const diffY = e.pageY - this.zoomPositions.pageY;

				const top = this.zoomPositions.startTop + diffY;
				const left = this.zoomPositions.startLeft + diffX;

				this.current.x = left;
				this.current.y = top;

				imageEl.css({ top: `${top}px`, left: `${left}px` });
				//this.zoomPositions.x = e.x;
				// this.zoomPositions.y = e.y;
				// const centerY = (this.$lib.app.stage.height - imageEl.clientHeight) / 2;
				// const centerX = (this.$lib.app.stage.width - imageEl.clientWidth) / 2;

				// if (
				//	 top >= imageEl.clientHeight / 2 + centerY ||
				//	 top <= imageEl.clientHeight / 2 - centerY
				// ) {
				//	 top = imageEl.offsetTop;
				// }

				// if (
				//	 left >= imageEl.clientWidth / 2 + centerX ||
				//	 left <= imageEl.clientWidth / 2 - centerX
				// ) {
				//	 left = imageEl.offsetLeft;
				// }

				// imageEl.setAttribute("style", `top: ${top}px; left: ${left}px;`);
			}
		},

		calculateZoomPosition(e, calc = false) {
			if(calc) {
				const dragElement = document.getElementsByClassName("editing_img_wrpr")[0];

				const { width, height } = document.querySelector('.fabricSlide').getBoundingClientRect();
				let zoom = this.$lib.app.zoomProcentage;

				const marginLeft = parseInt($('.main_n_footer_wrpr').css('margin-left'));
				const marginTop = document.querySelector('.header').getBoundingClientRect().height;

				let oz = this.$lib.app.zoomProcentage;
				let nz = zoom;
				/// offset of container
				let ox = (width / 2) + ( _LIB.app._canvasSize.w / 2) - (marginLeft / 2);

				let oy = (height / 2) + marginTop;

				let mx = e.clientX - ox;
				let my = e.clientY - oy;

				let ix = (mx - this.current.x) / oz;
				let iy = (my - this.current.y) / oz;

				let nx = ix * nz;
				let ny = iy * nz;

				let cx = (ix + (mx - ix) - nx);
				let cy = (iy + (my - iy) - ny);

				this.current.zoom = nz;
				this.current.x = cx;
				this.current.y = cy;

				// this.current = { ...this.current, x: left, y: top };

				const left = this.current.x;
				const top = this.current.y;

				return { left, top };
			}

			return {
				//left, top,
				startLeft: parseFloat($('.centered-content').css('left')) || 0,
				startTop: parseFloat($('.centered-content').css('top')) || 0,
				pageX: e.pageX, pageY: e.pageY
			};
		},

		async zoomOnMouseUp(e) {
			const isSpace = !!this.keyDownList.find((key) => key == 32);

			if (this.zoomPositions.isDrag && /* this.$lib.fabric.isZoomMode && */ isSpace) {
				// $('#app').append(`<span style="
				// 	pointer-events: none;
				// 	border-radius: 50%;
				// 	width: 10px;
				// 	height: 10px;
				// 	background-color: rgba(255, 0, 0, .5);
				// 	left: ${this.zoomPositions.pageX}px;
				// 	top: ${this.zoomPositions.pageY}px;
				// 	position: absolute;">
				// </span>`);
				/* const imageEl = document.querySelector(".editing_img_wrpr");
				const style = imageEl
					.getAttribute("style")
					.replace("cursor: move;", "");
				imageEl.setAttribute("style", `${style} cursor: auto`); */

				this.zoomPositions = {
					isDrag: false,
				};

				this.current = {
					...this.current,
					zoom: this.$lib.app.zoomProcentage
				};
			}
		},

		async dropImage(e) {
			e.preventDefault();
			try {
				const bounds = e.target.getBoundingClientRect();
				const dataDrop = JSON.parse(e.dataTransfer.getData("dataDrop"));

				let data = {
					...dataDrop,
					x: e.x - bounds.x - dataDrop.offsetX / window.devicePixelRatio,
					y: e.y - bounds.y - dataDrop.offsetY / window.devicePixelRatio,
				};
				console.log("on drop",dataDrop);

				this.$store.dispatch("preloader", {
					message: "Uploading...",
					isShow: true,
				});

				if (data.layerType == "graphics") {
					if(navigator.onLine){
						await fetch(
							`${this.$store.state.prefixURL}/api/graphics/update_statistic/${data.id}`
						).then((res) => res.json());
					}
				}

				await this.$lib.app.renderAsLayerDrop(data);
				this.$store.dispatch("isUpdateCache");
				this.$store.dispatch("preloader", {});
			} catch (err) {
				//console.error(err);
			}
		},

		setupPreloader(message, isShow) {
			this.$store.dispatch("preloader", { isShow, message });
		},

		setupPreloadedAndHide(message, time) {
			this.setupPreloader(message, true);

			setTimeout(() => this.setupPreloader("", false), time);
		},

		renderCanvas: function (file) {
			if (!file) return;
			this.$store.dispatch("userFile", false);
			// this.$store.state.sidebarPanel.panelName = "Edit";
			let reader = new FileReader();
			reader.onload = async (e) => {
				// this.fsReaderFile = e.target.result;
				this.fsReaderFile = await this.resizeImage(e.target.result,file.type,this.isMobileView)
				await this.$store.dispatch("isInitialLib", true);
				if (this.fsReaderFile.includes("data:application/octet-stream")) {
					return this.$lib._project.uploadProject(file, async (err, data = {}) => {
						this.setupPreloader(
							data.progress || data.progress === 0
								? `Unpacked for ${parseInt(data.progress)}%`
								: null,
							true
						);

						// \n${data.size ? "(" + data.size + " byte)" : ""}

						if (err) {
							console.error(err);
							this.setupPreloadedAndHide("Error project upload!", 1000);
						}

						if (data.isCompleted) {
							this.setupPreloadedAndHide("Project uploaded!", 1000);
							this.$store.dispatch("isUpdateCache");
							this.$lib.app.addTransparentPNGMask();
							// this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();
							this.$store.dispatch("sidebarPanelToggle", {
								panelName: this.hash ?? (this.isMobileView ? 'Effects' : 'Edit'),
								panelClass: "active"
							});
						}
					});
				} else {
					this.setupPreloader("Uploading...", true);
					this.$lib.app.renderSprite({ url: this.fsReaderFile }, async () => {
						this.$store.dispatch("isUpdateCache");
						/* To Set Border Scale*/
						let value = this.$lib.app.zoomProcentage;
						if (this.$lib.app.zoomProcentage != 1) {
							this.$lib.app.scaleStage(1);
							this.$lib.app.setZoomProcentage(1, true);
						}
						this.$lib.app.addTransparentPNGMask();
						// this.$store.state.effectServerURL = await this.$lib.app.uploadFileToEffectsServer();

						$(".centered-content").css({'transform': `scale(${value})`});
            this.$lib.app.setFabricZoom(value);

						this.$store.dispatch("zoomDashboard", value);
						this.setupPreloadedAndHide("Image uploaded!", 1000);

						setTimeout(() => {
							this.$lib.app.fabricCanvas.renderAll();
							this.$store.dispatch("sidebarPanelToggle", {
								panelName: this.hash ?? (this.isMobileView ? 'Effects' : 'Edit'),
								panelClass: "active"
							});
						}, 1);
					});
				}
			};

			reader.readAsDataURL(file);
		},
		async resizeImage(result,filetype,isMobileView) {
			return new Promise((resolve, reject) => {
				let image = new Image();
				image.src = result;
				image.onload = function() {
					// access image size here
					let canvas = document.createElement("canvas");
					let ctx = canvas.getContext("2d");
					ctx.drawImage(this, 0, 0);

          let MAX_WIDTH = isMobileView ? 1920 : 2560;
          let MAX_HEIGHT = isMobileView ? 1920 : 2560;
					let width = this.width;
					let height = this.height;

					if (width > height) {
						if (width > MAX_WIDTH) {
							height *= MAX_WIDTH / width;
							width = MAX_WIDTH;
						}
					} else {
						if (height > MAX_HEIGHT) {
							width *= MAX_HEIGHT / height;
							height = MAX_HEIGHT;
						}
					}
					canvas.width = width;
					canvas.height = height;
					ctx = canvas.getContext("2d");
					ctx.drawImage(this, 0, 0, width, height);
					resolve(canvas.toDataURL(filetype));
				}
				image.onerror = reject
			})

		},

	},
	watch: {
		userFile: function (val) {
			this.renderCanvas(val);
		},
	},
};
</script>

<style>
.editing_img_wrpr.with_element {
	height: 100%;
	max-height: 100%;
	width: 100%;
	max-width: 100%;
}
.editing_img_wrpr .image_element {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.fabric-canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#canvasDashboard {
	display: block;
}

#fabricDashboard {
	position: relative !important;
}

.absolute-position {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.centered-content {
	display: inline-block;
	position: relative;
}
.canvasview {
	background-image: url('../assets/images/square-bg.png');
	background-size: 40px 40px;
	position: absolute;
	z-index: 1;
}
body .image_element:not(.active):hover {
	/* border-color: #249ACF; */
	border-color: transparent;
}

.ch-popups {
	position: fixed;
	height: 100px;
	background: #2a2a2a;
}

.btns-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.contextMenu {
	width: 200px;
	position: absolute;
	display: none;
	background: #5c5b5b;
}

.contextButton {
	border: none;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	color: #d7dcdd;
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	padding: 8px 14px 9px 18px;
	background: #2a2a2a;
	width: 100%;
}

.contextButton:hover {
	color: #fff;
	background: #353535;
}

.changes_applied_wrpr.changes_applied_mobile {
  left: 0;
  width: 100vw;
}
.changes_applied_wrpr.changes_applied_mobile .plus_watermark.сhanges_applied {
  padding: 22px 36px;
  border-radius: 6px;
  opacity: 0.8;
}
.changes_applied_wrpr.changes_applied_mobile .plus_watermark.сhanges_applied .title {
  font-size: 13px;
}

</style>
