<template>
  <div v-if="isMobileView" class="upgrade-feature-mobile-wrpr" style="pointer-events: all;">
    <div class="upgrade-feature-mobile">
      <div>
        <p>Upgrade to Colorcinch Plus</p>
        <p>to unlock this feature</p>
      </div>
      <a @click="openUpgradePop()">Upgrade</a>
    </div>

  </div>
</template>

<script>
export default {
    name: "PremiumInfo",
    props: {
        isMobileView: Boolean
    },
    methods: {
        openUpgradePop() {
            this.$store.dispatch("popupsToggle", { property: "stripeCheckoutPopup", value: true });
        }
    },
    mounted() {
      const el = _LIB.app.fabricCanvas.height + ($('.image_element_mobile > .absolute-position > .centered-content').offset().top
          - $(".header").height()) -  $(".upgrade-feature-mobile-wrpr").height() - 12;
      $(".upgrade-feature-mobile-wrpr").css({'top': `${el}px`});
    }
}
</script>
<style scoped>
    .upgrade-feature-mobile-wrpr {
        position: absolute;
        z-index: 9999;
        display: flex;
        width: 100%;
        justify-content: center;
        max-height: 68px;
        max-width: 320px;
    }
    .upgrade-feature-mobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 14px 16px;
        background: #FFFFFF;
        color: #1D1D1D;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        border: 1px solid #ECECEC;
        border-radius: 6px;

    }
    .upgrade-feature-mobile a {
        padding: 10px 21px;
        color: #FA6400;
        text-align: center;
        background: #FFF1E6;
        border-radius: 100px;
    }
</style>
