<template>
	<div class="popup_body radius-4">
		<div>
			<div v-show="showCloseButton" style="display: flex;margin: 9px 9px -17px 0px;float: right;">
				<a href="#" @click.prevent="hideAdPopUp"  class="close">
				</a>
			</div>
			<AdPanel v-if="isSaveAd" :content="isSaveAdContent" adPosition="center"  />
		</div>
	</div>
</template>

<script>
	import AdPanel from "@/components/AdPanel.vue";

	export default {
		name: "AdSavePanel",
		data() {
			return {
				showCloseButton: false
			};
		},
		components: {
			AdPanel
		},
		computed: {
			isSaveAd() {
				return (!this.$store.state.isPremiumAccount && this.$store.state.ADINFO?.is_ads && this.$store.state.ADINFO?.save_ad?.is_save_ad) ?? false;
			},
			isSaveAdContent() {
				if(!this.isSaveAd) return ''
				return this.$store.state.ADINFO?.save_ad?.save_script ?? '' ;
			},
		},
		mounted: function(){
			setTimeout(()=>{
				this.showCloseButton = true
			},3000)
		},
		methods: {
			hideAdPopUp(){
				this.$store.dispatch("popupsToggle", {
					property: "afterSaveAd",
					value: false
				});
			}

		},
	};
</script>

<style scoped>


	.close {
		width: 8px;
		height: 8px;
		background: transparent url('../assets/svg/notification_close_2.svg') 0% 0% no-repeat padding-box;
		flex-shrink: 0;
	}


</style>
